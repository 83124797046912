/**
 * 横書きのページで、部分的な縦書きを実現する。
 *
 * **実装上の注意**
 * : horizontal.css が優先されているときもうまく動くようにするため、詳細度を `:where` 等で下げてはならない。
 *
 * <!-- FIXME: 自動展開したい -->
*/

/* cf. ./vertical.css */

@custom-selector :--blockquote blockquote, .blockquote-like;
@custom-selector :--image figure, img, picture, svg;

%vertical {
  writing-mode: vertical-rl;
}

.vertical {
  @extend %vertical;
}

/* .vertical を元に戾す */
%revert-class-vertical {
  writing-mode: revert;
  writing-mode: horizontal-tb; /* Safari (15.1, iOS 15.2) では継承するか直接指定するかで振る舞いが変わることがあるので、明示的に指定している。 TODO: Delete this line */
}

:is(p, :--blockquote).vertical {
  height: min(
    max(
      29em,

      /* 100vw (w/ scroll bar) / √2 - (.body margin-inline) */
      100vw / 1.4142135624 - 2 * clamp(
        1rem,
        100vmin * 2.618034 / (2 * 2.618034 + 29),
        2.618034rem
      )
    ),

    /* 100vh - (margin-top-bottom itself) */
    100vh - 2em
  );

  max-height: fit-content;

  /* Use the same numbers as in horizontal.css. */
  @media (min-aspect-ratio: 1.334279) and (min-width: 848.498448px) {
    height: min(
      max(
        29em,

        /* 50vw (w/ half of scroll bar) / √2 - (1 - 1 / (4√2)) (.body margin-inline) */
        50vw / 1.4142135624 - (1 - 1 / 4 / 1.4142135624) * 2 * clamp(
          1rem,
          100vmin * 2.618034 / (2 * 2.618034 + 29),
          2.618034rem
        )
      ),

      /* 100vh - (margin-top-bottom itself) */
      100vh - 2em
    );
  }
}

.vertical p,
p.vertical {
  /* layout.css */
  margin: 1em 0;

  /* 右から書き始めるため、幅を全部取る必要がある。 */
  width: 100%;

  overflow-x: auto;
  overflow-inline: auto;

  hanging-punctuation: none; /* 左右に余白が無いため、句読点をぶら下げると垂直スクロールが発生する。 */
}

/* TODO: blockquote > p 以外の構造でもうまく動くようにする? */
:--blockquote.vertical {
  margin: 1em 0; /* ./reset.css */
  border-width: 0 calc(2 / 13 * 1rem);
  padding: 0 calc(1rem - 2 / 13 * 1rem);
  box-sizing: border-box;
  width: 100%;
  overflow-x: auto;
  overflow-y: revert;
}

:--blockquote.vertical > p {
  @extend %vertical;

  margin: revert;
  width: revert;
}

.vertical a:not(:has(:--image)):any-link.external::before,
a:not(:has(:--image)):any-link.external.vertical::before {
  display: inline-block;
  display: inline flow-root;
  font: var(--fa-font-solid);
  content: '\f35d'; /* fa-up-right-from-square */
  font-size: 61.8034%; /* φ⁻¹ */
  color: var(--color-text-dimmed);
  vertical-align: super;
  margin-inline-end: 2px;
  text-emphasis: none; /* Not sesamed */
}

.vertical a:not(:has(:--image)):any-link.external::after,
a:not(:has(:--image)):any-link.external.vertical::after {
  content: none;
}

/* TODO: 主要ブラウザーと css-has-pseudo が :lang(ja, zh) をサポートしたら、このルールセットを削除する。 */
.vertical ruby:lang(ja):has(rt:lang(ja)),
.vertical ruby:lang(ja):has(rt:lang(jpn-archaic)),
.vertical ruby:lang(ja):has(rt:lang(zh)),
.vertical ruby:lang(jpn-archaic):has(rt:lang(ja)),
.vertical ruby:lang(jpn-archaic):has(rt:lang(jpn-archaic)),
.vertical ruby:lang(jpn-archaic):has(rt:lang(zh)),
.vertical ruby:lang(zh):has(rt:lang(ja)),
.vertical ruby:lang(zh):has(rt:lang(jpn-archaic)),
.vertical ruby:lang(zh):has(rt:lang(zh)),
ruby:lang(ja):has(rt:lang(ja)).vertical,
ruby:lang(ja):has(rt:lang(jpn-archaic)).vertical,
ruby:lang(ja):has(rt:lang(zh)).vertical,
ruby:lang(jpn-archaic):has(rt:lang(ja)).vertical,
ruby:lang(jpn-archaic):has(rt:lang(jpn-archaic)).vertical,
ruby:lang(jpn-archaic):has(rt:lang(zh)).vertical,
ruby:lang(zh):has(rt:lang(ja)).vertical,
ruby:lang(zh):has(rt:lang(jpn-archaic)).vertical,
ruby:lang(zh):has(rt:lang(zh)).vertical,
.vertical ruby:lang(ja):has(> rt:lang(ja)),
.vertical ruby:lang(ja):has(> rt:lang(jpn-archaic)),
.vertical ruby:lang(ja):has(> rt:lang(zh)),
.vertical ruby:lang(jpn-archaic):has(> rt:lang(ja)),
.vertical ruby:lang(jpn-archaic):has(> rt:lang(jpn-archaic)),
.vertical ruby:lang(jpn-archaic):has(> rt:lang(zh)),
.vertical ruby:lang(zh):has(> rt:lang(ja)),
.vertical ruby:lang(zh):has(> rt:lang(jpn-archaic)),
.vertical ruby:lang(zh):has(> rt:lang(zh)),
ruby:lang(ja):has(> rt:lang(ja)).vertical,
ruby:lang(ja):has(> rt:lang(jpn-archaic)).vertical,
ruby:lang(ja):has(> rt:lang(zh)).vertical,
ruby:lang(jpn-archaic):has(> rt:lang(ja)).vertical,
ruby:lang(jpn-archaic):has(> rt:lang(jpn-archaic)).vertical,
ruby:lang(jpn-archaic):has(> rt:lang(zh)).vertical,
ruby:lang(zh):has(> rt:lang(ja)).vertical,
ruby:lang(zh):has(> rt:lang(jpn-archaic)).vertical,
ruby:lang(zh):has(> rt:lang(zh)).vertical {
  ruby-align: start;
  ruby-overhang: auto;
}

/* TODO: 主要ブラウザーと css-has-pseudo が :has(> E) をサポートしたら、上2つのセレクターを削除する。 */
.vertical ruby:lang(ja, jpn-archaic, zh):has(rt:lang(ja, jpn-archaic, zh)),
ruby:lang(ja, jpn-archaic, zh):has(rt:lang(ja, jpn-archaic, zh)).vertical,
.vertical ruby:lang(ja, jpn-archaic, zh):has(> rt:lang(ja, jpn-archaic, zh)),
ruby:lang(ja, jpn-archaic, zh):has(> rt:lang(ja, jpn-archaic, zh)).vertical {
  /* TODO: 熟字訓のグループルビは中央寄せする。 */
  /* TODO: 4文字以上のルビは下から順に上下に1文字づつ追い出す。 */
  /* TODO: 行頭、行末ではルビを版面の外に出さない。 */
  ruby-align: start;
  ruby-overhang: auto;
}

.vertical em,
em.vertical {
  font-style: normal;
  font-family: var(--font-text-emphasized);
}

/* .vertical em,
em.vertical, */
.vertical em :where([lang]),
em.vertical :where([lang]),
em .vertical:where([lang]) {
  font-style: italic;
}

/* Same as in vertical.css */
.vertical .upright,
.upright.vertical {
  font-feature-settings: "vkna", "fwid";
  text-orientation: upright;
}

.vertical .tate-chu-yoko,
.tate-chu-yoko.vertical {
  -webkit-text-combine: horizontal; /* for Webkit */
  text-combine-upright: all;
}

.vertical table,
table.vertical {
  margin-inline: revert;
}
